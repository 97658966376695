import useJwt from '@src/@core/auth/jwt/useJwt'
import * as Constants from '../constants'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}

export const getUserJWT = () => {
  let result = null
  try {
    result = JSON.parse(localStorage.getItem('userData')).jwt.replace(/\'/g, '')
  } catch (error) {
    console.log(error)
  }
  return result
}

export const getUserStrapi = async () => {
  let user = ''
  try {
    const token = JSON.parse(localStorage.getItem('userData')).jwt.replace(/\'/g, '')
    const axios = require('axios')
    const config = {
      method: 'get',
      url: `${Constants.REST_API}/users/me`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
    await axios(config)
      .then(function (response) {
        user = response
      })
      .catch(function (error) {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
  return user
}

export const authenGoogleStrapi = async (user) => {
  const axios = require('axios')
  const data_upu = JSON.stringify({
    query: `query UsersPermissionsUsers($filters: UsersPermissionsUserFiltersInput){
        usersPermissionsUsers(filters: $filters) {
            data {
              id
            }
        }
    }`,
    variables: { filters: { email: { eq: user.email } } }
  })

  const config = {
    method: 'post',
    url: Constants.GRAPHQL_API,
    headers: {
      'Content-Type': 'application/json'
    },
    data: data_upu
  }

  let idu = null
  await axios(config)
    .then(function (response) {
      if (response.data.data.usersPermissionsUsers.data.length > 0) {
        idu = response.data.data.usersPermissionsUsers.data[0].id
      }
    })
    .catch(function (error) {
      console.log(error)
    })

  let pass = null
  if (idu !== null) {
    const data2 = JSON.stringify({
      query: `mutation UpdateUsersPermissionsUser($id: ID!,$data: UsersPermissionsUserInput!) {
      updateUsersPermissionsUser(id: $id, data: $data) {
        data {
          id
        }
      }
    }`,
      variables: {
        id: idu,
        data: {
          // confirmed: true, 
          password: user.id
        }
      }
    })
    const config2 = {
      method: 'post',
      url: Constants.GRAPHQL_API,
      headers: {
        Authorization: Constants.TOKEN_API,
        'Content-Type': 'application/json'
      },
      data: data2
    }
    await axios(config2)
      .then(function (response2) {
        if (response2.data.data.updateUsersPermissionsUser) {
          pass = user.id
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  } else {
    const uname_g = `${user.name.replace(' ', '-')}-google`
    const data_cr = JSON.stringify({
      query: `mutation CreateUsersPermissionsUser($data: UsersPermissionsUserInput!) {
      createUsersPermissionsUser(data: $data) {
        data {
          id
        }
      }
    }`,
      variables: { data: { username: uname_g, email: user.email, password: user.id, confirmed: true, blocked: false, role: 1, name: user.name, registerDate: new Date() } }
    })

    const config3 = {
      method: 'post',
      url: Constants.GRAPHQL_API,
      headers: {
        Authorization: Constants.TOKEN_API,
        'Content-Type': 'application/json'
      },
      data: data_cr
    }

    await axios(config3)
      .then(function (response3) {
        if (response3.data.data.createUsersPermissionsUser) {
          pass = user.id
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  return pass
}

export const formatNewLine = (content) => {
  let new_fm = null
  if (content !== null && typeof content !== "undefined") {
    new_fm = content.split('\n').map((item, key) => {
      return <Fragment key={key}>{item}<br /></Fragment>
    })
  }
  return new_fm
}

// List of blocked keywords
export const containsBlockedKeyword = (text) => {
  const lowercasedText = text?.toLowerCase();
  return Constants.blockedKeywords?.some(keyword => lowercasedText?.includes(keyword));
};