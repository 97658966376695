/* eslint-disable */
import axios from 'axios'
import { MD5 } from 'crypto-js'
import jwt from 'jsonwebtoken'
import * as Constants from './../../../constants'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = this.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          // config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              // originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getJWT() {
    return localStorage.getItem(this.jwtConfig.storageUserData)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  async login(...args) {
    const jwtConfig = {
      secret: 'dd5f3089-40c3-403d-af14-d0c228b05cb4',
      refreshTokenSecret: '7c4c1c50-3230-45bf-9eae-c9b2e401c767',
      expireTime: '10m',
      refreshTokenExpireTime: '10m'
    }

    let error = {
      email: ['Something went wrong']
    }

    let user_sp = {}
    const axios = require('axios')
    const data2 = JSON.stringify({
      identifier: args[0].email,
      password: args[0].password
    })

    const config = {
      method: 'post',
      url: Constants.AUTHOR_API,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data2
    }

    await axios(config)
      .then(function (response) {
        user_sp = response.data
      })
      .catch(function (error) {
        console.log(error)
      })

    if (user_sp.user) {
      try {
        const user_id = user_sp.user.id
        const hash = MD5(user_sp.user.email).toString()
        user_sp.id = user_id
        user_sp.fullName = user_sp.user.name
        user_sp.username = user_sp.user.username
        user_sp.password = ''
        user_sp.avatar = `https://www.gravatar.com/avatar/${hash}`
        user_sp.email = user_sp.user.email
        user_sp.role = 'client'
        user_sp.ability = [
          {
            action: 'read',
            subject: 'ACL'
          },
          {
            action: 'read',
            subject: 'Auth'
          }
        ]
        user_sp.extras = {
          eCommerceCartItemsCount: 0
        }

        const accessToken = jwt.sign({ id: user_id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })
        const refreshToken = jwt.sign({ id: user_id }, jwtConfig.refreshTokenSecret, {
          expiresIn: jwtConfig.refreshTokenExpireTime
        })

        const userData = { ...user_sp }

        delete userData.password

        const response = {
          userData,
          accessToken,
          refreshToken
        }
        // Create user-profile strapi4
        // Kiem tra id_already tren local
        const id_already = JSON.parse(localStorage.getItem('id_already'));
        
        if (!id_already) {
          let userIdStrapi4 = ''
          const config = {
            method: 'get',
            url: `${Constants.REST_API_4}/user-profiles?filters[$and][0][user_id][$eq]=${user_id}`
          }
          await axios(config)
            .then(function (response) {
              userIdStrapi4 = response?.data?.data?.[0]?.id
              if (!userIdStrapi4) {
                let data_profile = {}
                data_profile.user_id = user_id
                data_profile.fullName = user_sp.user.name
                data_profile.username = user_sp.user.username
                data_profile.name = user_sp.user.username
                const configCreate = {
                  method: 'post',
                  url: Constants.REST_SPAM_URL,
                  data: {
                    data: data_profile,
                    token: user_sp?.jwt,
                    type: 'user-profiles',
                  },
                  headers: {
                    Authorization: `Bearer ${user_sp?.jwt}`
                  }
                }
                axios(configCreate)
                  .then(function (response) {
                    localStorage.setItem('id_already', JSON.stringify(response?.data?.data?.attributes?.user_id));
                  })
                  .catch(function (error) {
                    console.log(error)
                  })
              } else {
                localStorage.setItem('id_already', JSON.stringify(userIdStrapi4));
              }
            })
            .catch(function (error) {
              console.log(error)
            })
        }
        return [200, response]
      } catch (e) {
        error = e
      }
    } else {
      error = {
        email: ['Email or Password is Invalid']
      }
    }
    return [400, { error }]
  }
  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }
  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }
}
