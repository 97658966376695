import { getUserData } from '../src/auth/utils'

export const blockedKeywords = [
  'buy',
  'order',
  'purchase',
  'sell',
  'earn',
  'money',
  'best',
  'verified',
]
export const userId = getUserData()
export const GRAPHQL_API = 'https://data2.cloodo.com/graphql'
export const AUTHOR_API = 'https://data2.cloodo.com/api/auth/local'
export const REST_API = 'https://data2.cloodo.com/api'
export const TOKEN_API = 'D9ABCC03D2FC87DB89DAE2BA20DCAB55'
export const Client_ID = '721561722913-fs6fp8ef2g4te10qmce9u6pd17o9d3hm.apps.googleusercontent.com'
export const NAME_APP = 'Workspace'
export const REST_API_4 = 'https://strapi4.cloodo.com/api'
export const REST_REPORT_API_4 = 'https://strapi4.cloodo.com/cloodo-reports'
export const REG_API = 'https://data2.cloodo.com/api/user/registerAccount'
export const URL_FONTEND = 'https://cloodo.com'
export const URL_FONTEND2 = 'https://workplace.cloodo.com'
export const REST_API_WORKSUITE = 'https://erp.cloodo.com/api/v1/'
export const SEVER_CHAT = 'https://socket.cloodo.com'
export const ICON_LOGO_DEFAULT = 'https://cloodo.com/logo.png'
export const REST_SPAM_URL = 'https://data2.cloodo.com/api/user/execProjectErp'
export const API_CLOODO = 'https://api.cloodo.com/v1/'
export const CONVERT_CLOODO = 'https://convert.cloodo.com/api/'
export const REST_API_WORKSUITE_V1 = 'https://erp.cloodo.com/api/v1/'
export const REST_API_WORKSUITE_V2 = 'https://erp.cloodo.com/api/v2/'
export const REST_API_VCARD = 'https://convert.cloodo.com/api/map-widget-strapi/'
export const REST_API_WORKSUITE_V3 = 'https://erp-amz.cloodo.com/v4/'
export const REST_API_WORKSPACE = 'https://workspace.cloodo.com'
export const REST_API_PASSWORD = 'https://data2.cloodo.com/api/users-permissions/reset-password'
export const REST_API_DATA2 = 'https://data2.cloodo.com/api/'
