import mock from '../mock'

export const searchArr = [
  {
    groupTitle: 'Pages',
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: 'post',
        isBookmarked: true,
        title: 'Add New Post',
        icon: 'FilePlus',
        link: '/apps/short-topic/add-topic'
      },
      {
        id: 2,
        target: 'project',
        isBookmarked: true,
        title: 'Add New Project',
        icon: 'FileText',
        link: '/apps/new-project/add-projects'
      },
      {
        id: 3,
        target: 'agency',
        isBookmarked: true,
        title: 'Add New Agency',
        icon: 'CheckSquare',
        link: '/apps/agencies/new-agency/add-agency'
      },
      {
        id: 4,
        target: 'service',
        isBookmarked: true,
        title: 'Add New Service',
        icon: 'Box',
        link: '/apps/services/list-service/add-projects'
      },
      {
        id: 5,
        target: 'tutorial',
        isBookmarked: true,
        title: 'Add New Tutorial',
        icon: 'FileMinus',
        link: '/apps/tutorial-topic/add-tutorial'
      },
      // {
      //   id: 6,
      //   target: 'review',
      //   isBookmarked: true,
      //   title: 'Add New Review',
      //   icon: 'Bookmark',
      //   link: '/apps/review-project'
      // }
    ]
  },
  {
    groupTitle: 'Files',
    searchLimit: 4,
    data: [
      {
        title: 'Passport Image',
        by: 'Oliver Queen',
        size: '52kb',
        file: require('@src/assets/images/icons/jpg.png').default
      },
      {
        title: 'Parenting Guide',
        by: 'Alfred Pennyworth',
        size: '2.3mb',
        file: require('@src/assets/images/icons/doc.png').default
      },
      {
        title: 'Class Notes',
        by: 'Barry Allen',
        size: '30kb',
        file: require('@src/assets/images/icons/doc.png').default
      },
      {
        title: 'Class Attendance',
        by: 'Walter White',
        size: '52mb',
        file: require('@src/assets/images/icons/xls.png').default
      }
    ]
  },
  {
    groupTitle: 'Contacts',
    searchLimit: 4,
    data: [
      {
        title: 'Mia Davis',
        email: 'miadavis@teleworm.us',
        img: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
        date: '01/03/2020'
      },
      {
        title: 'Norris Carrière',
        email: 'NorrisCarriere@rhyta.com',
        img: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
        date: '07/03/2020'
      },
      {
        title: 'Charlotte Gordon',
        email: 'CharlotteGordon@jourrapide.com',
        img: require('@src/assets/images/portrait/small/avatar-s-26.jpg').default,
        date: '14/03/2020'
      },
      {
        title: 'Robert Nash',
        email: 'RobertNash@dayrep.com',
        img: require('@src/assets/images/portrait/small/avatar-s-25.jpg').default,
        date: '21/03/2020'
      }
    ]
  }
]

// GET Search Data
mock.onGet('/api/main-search/data').reply(() => {
  return [200, { searchArr }]
})

// GET Search Data & Bookmarks
mock.onGet('/api/bookmarks/data').reply(() => {
  const bookmarks = searchArr[0].data.filter(item => item.isBookmarked)
  const suggestions = searchArr[0].data
  return [200, { suggestions, bookmarks }]
})

// POST Update isBookmarked
mock.onPost('/api/bookmarks/update').reply(config => {
  const { id } = JSON.parse(config.data)

  const obj = searchArr[0].data.find(item => item.id === id)

  Object.assign(obj, { isBookmarked: !obj.isBookmarked })

  return [200]
})
