// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as Constants from './../../../../constants'
import { getUserJWT, getUserData } from '../../../../auth/utils'

// import { ApolloClient, gql } from 'apollo-boost'
// import { InMemoryCache } from 'apollo-cache-inmemory'
// import { createHttpLink } from 'apollo-link-http'

// ** Axios Imports
import axios from 'axios'
import { MD5 } from 'crypto-js'

// const client = new ApolloClient({
//   link: createHttpLink({ uri: Constants.GRAPHQL_API }),
//   cache: new InMemoryCache(),
//   defaultOptions: {
//     watchQuery: {
//       fetchPolicy: 'no-cache',
//       errorPolicy: 'ignore'
//     },
//     query: {
//       fetchPolicy: 'no-cache',
//       errorPolicy: 'all'
//     }
//   }
// })

export const getMails = createAsyncThunk('appEmail/getMails', async params => {
  sessionStorage.setItem("ticket_load", "start")
  let tickets = null
  const data_qr = {
    ctoken: Constants.TOKEN_API,
    ujwt: getUserJWT()
  }
  if (params.label) {
    data_qr.status_tk = params.label
  }
  if (params.folder !== 'inbox') {
    data_qr.project_tk = params.folder
  }
  if (params.potential) {
    data_qr.potential = params.potential
  }
  if (params.filter_by) {
    data_qr.filter_by = params.filter_by
  }

  try {
    if (params.q) {
      data_qr.q = params.q
    }
  } catch (err) {
    console.log(err.message)
  }

  if (params.filter === 'feedback') {
    const config = {
      method: 'get',
      url: `${Constants.REST_API}/user/ticketTypeReply?filter=newticketreply&ctoken=${Constants.TOKEN_API}`,
      headers: {
        'content-type': 'application/json'
      }
    }

    await axios(config)
      .then(function (response) {
        tickets = response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  } else {
    const config = {
      method: 'post',
      url: `${Constants.REST_API}/user/channelTickets`,
      headers: {
        'content-type': 'application/json'
      },
      data: data_qr
    }
    await axios(config)
      .then(function (response) {
        tickets = response.data
        if (typeof tickets.role !== "undefined") {
          const user_dt = getUserData()
          if (typeof user_dt !== "undefined" && user_dt !== null) {
            user_dt.rl = tickets.role
            localStorage.setItem('userData', JSON.stringify(user_dt))
          }
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  try {
    tickets.emailsMeta = {
      inbox: 0,
      draft: 0,
      spam: 0
    }
    tickets.emails = []
    const supporttickets = tickets.data.supportTickets.data
    if (supporttickets.length > 0) {
      for (let i = 0; i < supporttickets.length; i++) {
        const temp_tk = {}
        const arr_rep = []
        const hash = MD5(supporttickets[i].attributes.user.data.attributes.email).toString()
        temp_tk.from = {
          email: supporttickets[i].attributes.user.data.attributes.email,
          avatar: `https://www.gravatar.com/avatar/${hash}`,
          name: supporttickets[i].attributes.user.data.attributes.name,
          id: supporttickets[i].attributes.user.data.id
        }
        temp_tk.to = {}
        if (supporttickets[i].attributes.assigned_user_id.data !== null) {
          const hash2 = MD5(supporttickets[i].attributes.assigned_user_id.data.attributes.email).toString()
          temp_tk.to = {
            name: supporttickets[i].attributes.assigned_user_id.data.attributes.name,
            email: supporttickets[i].attributes.assigned_user_id.data.attributes.email,
            avatar: `https://www.gravatar.com/avatar/${hash2}`
          }
        }
        temp_tk.id = supporttickets[i].id
        let isRead = true
        if (supporttickets[i].attributes.status.data.attributes.status === 'New') {
          isRead = false
        }
        const list_rep = supporttickets[i].attributes.replylist
        if (list_rep?.length > 0) {
          for (let j = 0; j < list_rep.length; j++) {
            if (list_rep[j].published) {
              const temp_rep = {}
              const hash2 = MD5(list_rep[j].user.data.attributes.email).toString()
              temp_rep.from = {
                id: list_rep[j].user.data.id,
                email: list_rep[j].user.data.attributes.email,
                avatar: `https://www.gravatar.com/avatar/${hash2}`,
                name: list_rep[j].user.data.attributes.name
              }
              temp_rep.message = list_rep[j].note
              temp_rep.time = list_rep[j].created
              arr_rep.push(temp_rep)
            }
          }
        }
        temp_tk.isRead = isRead
        temp_tk.message = supporttickets[i].attributes.note
        temp_tk.replies = arr_rep
        temp_tk.subject = supporttickets[i].attributes.subject
        temp_tk.time = supporttickets[i].attributes.updatedAt
        temp_tk.labels = [supporttickets[i].attributes.status.data.attributes.status]
        temp_tk.youradmin = (typeof supporttickets[i].attributes.youradmin !== "undefined" ? supporttickets[i].attributes.youradmin : null)
        temp_tk.yourftp = (typeof supporttickets[i].attributes.yourftp !== "undefined" ? supporttickets[i].attributes.yourftp : null)
        temp_tk.yourhost = (typeof supporttickets[i].attributes.yourhost !== "undefined" ? supporttickets[i].attributes.yourhost : null)
        temp_tk.yourwebsite = (typeof supporttickets[i].attributes.yourwebsite !== "undefined" ? supporttickets[i].attributes.yourwebsite : null)
        temp_tk.potential = supporttickets[i].attributes.potential
        tickets.emails.push(temp_tk)
      }
    }
  } catch (error) {
    console.log(error.message)
  }
  tickets.data = {}
  sessionStorage.setItem("ticket_load", "stop")
  return {
    params,
    data: tickets
  }
})

export const updateMails = createAsyncThunk(
  'appEmail/updateMails',
  async ({ emailIds, dataToUpdate }, { dispatch, getState }) => {
    let reload_tk = false
    if (dataToUpdate.isStarred) {
      reload_tk = true
    }
    if (reload_tk) {
      await dispatch(selectCurrentMail(emailIds[0]))
    }
    return {
      emailIds,
      dataToUpdate
    }
  }
)

export const updateMailLabel = createAsyncThunk(
  'appEmail/updateMailLabel',
  async ({ emailIds, label }, { dispatch, getState }) => {
    const response = await axios.post('/apps/email/update-emails-label', { emailIds, label })
    await dispatch(getMails(getState().email.params))
    return response.data
  }
)

export const paginateMail = createAsyncThunk('appEmail/paginateMail', async ({ dir, emailId }) => {
  const response = await axios.get('/apps/email/paginate-email', { params: { dir, emailId } })
  return response.data
})

export const selectCurrentMail = createAsyncThunk('appEmail/selectCurrentMail', async id => {
  sessionStorage.setItem("ticket_load", "start")
  let tickets = {}
  const data_qr = {
    ctoken: Constants.TOKEN_API,
    ujwt: getUserJWT(),
    id_tk: id
  }

  const config = {
    method: 'post',
    url: `${Constants.REST_API}/user/channelTickets`,
    headers: {
      'content-type': 'application/json'
    },
    data: data_qr
  }
  await axios(config)
    .then(function (response) {
      tickets = response.data
    })
    .catch(function (error) {
      console.log(error)
    })

  try {
    const supporttickets = tickets.data.supportTickets.data[0]
    if (supporttickets) {
      const temp_tk = {}
      const arr_rep = []
      const hash = MD5(supporttickets.attributes.user.data.attributes.email).toString()
      temp_tk.from = {
        email: supporttickets.attributes.user.data.attributes.email,
        avatar: `https://www.gravatar.com/avatar/${hash}`,
        name: supporttickets.attributes.user.data.attributes.name,
        id: supporttickets.attributes.user.data.id
      }
      if (supporttickets.attributes.assigned_user_id.data !== null) {
        const hash2 = MD5(supporttickets.attributes.assigned_user_id.data.attributes.email).toString()
        temp_tk.to = {
          name: supporttickets.attributes.assigned_user_id.data.attributes.name,
          email: supporttickets.attributes.assigned_user_id.data.attributes.email,
          avatar: `https://www.gravatar.com/avatar/${hash2}`
        }
      }
      temp_tk.id = supporttickets.id
      let isRead = true
      if (supporttickets.attributes.status.data.attributes.status === 'New') {
        isRead = false
      }
      temp_tk.isRead = isRead
      temp_tk.message = supporttickets.attributes.note
      const list_rep = supporttickets.attributes.replylist
      if (list_rep.length > 0) {
        for (let j = 0; j < list_rep.length; j++) {
          if (list_rep[j].published) {
            const temp_rep = {}
            const hash2 = MD5(list_rep[j].user.data.attributes.email).toString()
            temp_rep.from = {
              id: list_rep[j].user.data.id,
              email: list_rep[j].user.data.attributes.email,
              avatar: `https://www.gravatar.com/avatar/${hash2}`,
              name: list_rep[j].user.data.attributes.name
            }
            temp_rep.message = list_rep[j].note
            temp_rep.time = list_rep[j].created
            arr_rep.push(temp_rep)
          }
        }
      }
      temp_tk.replies = arr_rep
      temp_tk.subject = supporttickets.attributes.subject
      temp_tk.time = supporttickets.attributes.created
      temp_tk.labels = [supporttickets.attributes.status.data.attributes.status]
      temp_tk.youradmin = supporttickets.attributes.youradmin
      temp_tk.yourftp = supporttickets.attributes.yourftp
      temp_tk.yourhost = supporttickets.attributes.yourhost
      temp_tk.yourwebsite = supporttickets.attributes.yourwebsite
      temp_tk.potential = supporttickets.attributes.potential
      temp_tk.order_number = supporttickets.attributes.order_number
      tickets = temp_tk
    }
  } catch (error) {
    console.log(error.message)
  }
  sessionStorage.setItem("ticket_load", "stop")
  return tickets
})

export const appEmailSlice = createSlice({
  name: 'appEmail',
  initialState: {
    mails: [],
    params: {},
    emailsMeta: {},
    selectedMails: [],
    currentMail: null
  },
  reducers: {
    selectMail: (state, action) => {
      const selectedMails = state.selectedMails
      if (!selectedMails.includes(action.payload)) {
        selectedMails.push(action.payload)
      } else {
        selectedMails.splice(selectedMails.indexOf(action.payload), 1)
      }
      state.selectedMails = selectedMails
    },
    selectAllMail: (state, action) => {
      const selectAllMailsArr = []
      if (action.payload) {
        selectAllMailsArr.length = 0
        state.mails.forEach(mail => selectAllMailsArr.push(mail.id))
      } else {
        selectAllMailsArr.length = 0
      }
      state.selectedMails = selectAllMailsArr
    },
    resetSelectedMail: state => {
      state.selectedMails = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getMails.fulfilled, (state, action) => {
        let currMail = null
        if (state.currentMail !== null && state.currentMail !== undefined) {
          currMail = action.payload.data.emails.find(i => i.id === state.currentMail.id)
        }
        state.currentMail = currMail
        state.params = action.payload.params
        state.mails = action.payload.data.emails
        state.emailsMeta = action.payload.data.emailsMeta
      })
      .addCase(updateMails.fulfilled, (state, action) => {
        function updateMailData(email) {
          Object.assign(email, action.payload.dataToUpdate)
        }
        state.mails.forEach(email => {
          if (action.payload.emailIds.includes(email.id)) {
            updateMailData(email)
          }
        })
      })
      .addCase(paginateMail.fulfilled, (state, action) => {
        const data = action.payload
        const dataIndex = state.mails.findIndex(i => i.id === data.id)
        dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
        dataIndex === state.mails.length - 1 ? (data.hasNextMail = false) : (data.hasNextMail = true)
        state.currentMail = data
      })
      .addCase(selectCurrentMail.fulfilled, (state, action) => {
        state.currentMail = action.payload
      })
  }
})

export const { selectMail, selectAllMail, resetSelectedMail } = appEmailSlice.actions

export default appEmailSlice.reducer
